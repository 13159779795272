import * as React from "react"
import { Link } from "gatsby"
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const NotFoundPage = () => {
  const pageImages = useStaticQuery(graphql`
    query {
      logo: file(name: {eq: "logo"}) {
        childImageSharp {
          gatsbyImageData(
            width: 425,
            layout: CONSTRAINED, 
            quality: 100
          )
        }
      }
      divider: file(name: {eq: "divider"}) {
        childImageSharp {
          gatsbyImageData(
            width: 72,
            layout: CONSTRAINED, 
            quality: 100)
        }
      }
    }
  `)

  return (
    <Layout>
      <Meta title='Page Not Found' />
      <div id="coming-soon-page" class="grid">
        <div id="fourshore-divider" class="col-12">
          <GatsbyImage image={getImage(pageImages.divider)} alt="divider" />
        </div>
        <div id="fourshore-logo" class="col-12">
          <GatsbyImage image={getImage(pageImages.logo)} alt="Fourshore logo" />
        </div>
        <div id="band-description" class="col-12">
          <div>404 PAGE NOT FOUND </div>
          <div id="visit-home">
            <Link to="/">Home Page</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
